import { Head } from '@tecnobit-srl/head-manager';
import { usePage } from '@tecnobit-srl/router';
import {
  concat,
  filter,
  isArray,
  isEmpty,
  isObject,
  map,
  split,
  uniqBy,
} from 'lodash-es';
import { useMemo, type ReactNode, Fragment } from 'react';

const sanitize = (text?: string): string | undefined =>
  text?.replace(/"/g, '\\"');

export function Seo(): ReactNode {
  const pageProps = usePage();
  const { route, settings, locale } = pageProps;
  const { jsonSchemaorg } = settings;
  const {
    layoutOptions,
    breadcrumbs,
    url: currentPath,
    translations,
    canonical,
  } = route;
  const {
    title: _title,
    description: _description,
    jsonSchemas,
    images,
    video,
    noRobots,
    keywords,
  } = layoutOptions;

  const title = useMemo(() => sanitize(_title), [_title]);
  const description = useMemo(() => sanitize(_description), [_description]);

  const jsonSchemaInnerHTML = useMemo(() => {
    const mergedJsonSchemas = filter<object>(
      uniqBy(
        concat(
          jsonSchemas && isArray(jsonSchemas) ? jsonSchemas : [],
          jsonSchemaorg && isArray(jsonSchemaorg) ? jsonSchemaorg : [],
          [
            {
              '@context': 'http://schema.org',
              '@type': 'WebPage',
              description,
              image: images?.[0]?.url,
              inLanguage: split(locale, '-')[0],
              name: title,
              url: currentPath,
            },
          ],
          isArray(breadcrumbs) && breadcrumbs.length > 0
            ? [
                {
                  '@context': 'http://schema.org',
                  '@type': 'BreadcrumbList',
                  itemListElement: map(
                    filter(breadcrumbs, (b) => !isEmpty(b.url)),
                    (b, i) => ({
                      '@type': 'ListItem',
                      position: i,
                      item: {
                        '@id': b.url,
                        '@type': 'WebPage',
                        url: b.url,
                        name: b.title,
                      },
                    })
                  ),
                },
              ]
            : [],
          [
            {
              '@context': 'http://schema.org',
              '@type': 'Organization',
              description:
                "Siamo una software house che propone applicazioni e servizi in Italia e nel mondo sui temi che più ci appassionano: dal CAD 2D con GstarCAD, alla progettazione 3D con SketchUp, al design d'interni con Tilelook, oltre che alla formazione professionale online per Geometri, Architetti e Ingegneri. Tutte le nostre soluzioni sono all-inclusive (software, servizi web, supporto, aggiornamenti) e disponibili sottoforma di abbonamento, offerte da un'azienda con oltre 30 anni di esperienza e costantemente rivolta all'innovazione.",
              name: 'Tecnobit',
              legalName: 'Tecnobit S.r.l.',
              url: 'https://www.tecnobit.shop/',
              logo: '/img/logo/tecnobit_square.png',
            },
          ]
        ),
        '@type'
      ),
      (el) => isObject(el) && Boolean(el['@type'])
    );

    return {
      __html: JSON.stringify(mergedJsonSchemas),
    };
  }, [
    breadcrumbs,
    currentPath,
    description,
    images,
    jsonSchemas,
    locale,
    jsonSchemaorg,
    title,
  ]);

  return (
    <Head headKey="seo">
      <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      {keywords ? <meta name="keywords" content={keywords} /> : null}

      {map(translations, (translation, lang) => {
        const l = (
          <link
            key={`hreflang_${lang}`}
            rel="alternate"
            href={translation}
            hrefLang={lang}
          />
        );

        if (lang === 'en') {
          return (
            <Fragment key={`hreflang_${lang}`}>
              {l}
              <link rel="alternate" href={translation} hrefLang="x-default" />
            </Fragment>
          );
        }

        return l;
      })}

      {noRobots ? <meta name="robots" content="noindex, nofollow" /> : null}
      {canonical ? <link rel="canonical" href={canonical} /> : null}

      <meta property="og:type" content="website" />
      {Boolean(locale) && (
        <meta property="og:locale" content={split(locale, '-')[0]} />
      )}
      {map(translations, (_, lang) => (
        <meta property="og:locale:alternate" content={lang} key={lang} />
      ))}

      {Boolean(currentPath) && <meta property="og:url" content={currentPath} />}
      {Boolean(title) && <meta property="og:title" content={title} />}
      {Boolean(description) && (
        <meta property="og:description" content={description} />
      )}
      {Boolean(images) &&
        map(images, ({ url, alt }) => (
          <Fragment key={`og:image_${url}`}>
            <meta property="og:image" content={url} />
            {alt ? <meta property="og:image:alt" content={alt} /> : null}
          </Fragment>
        ))}
      {Boolean(video) && <meta property="og:video" content={video} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@tecnobitsoft" />
      <meta name="twitter:site" content="@tecnobitsoft" />
      {Boolean(title) && <meta name="twitter:title" content={title} />}
      {Boolean(description) && (
        <meta name="twitter:description" content={description} />
      )}
      {Boolean(images) &&
        map(images, ({ url, alt }) => (
          <Fragment key={`twitter:image_${url}`}>
            <meta property="twitter:image" content={url} />
            {alt ? <meta property="twitter:image:alt" content={alt} /> : null}
          </Fragment>
        ))}

      {Boolean(_title) && <title>{_title}</title>}
      {Boolean(description) && (
        <meta name="description" content={description} />
      )}
      {Boolean(images) &&
        map(images, ({ url }) => (
          <meta key={`image_${url}`} name="image" content={url} />
        ))}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={jsonSchemaInnerHTML}
      />
    </Head>
  );
}
