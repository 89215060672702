import { useMemo } from 'react';
import {
  useCoreCustomActions,
  type CustomActions,
} from '@tecnobit-srl/custom-actions';

export const useCustomActions = (): CustomActions => {
  const core = useCoreCustomActions();
  return useMemo(
    () => ({
      ...core,
    }),
    [core]
  );
};
