import { useMemo } from 'react';
import { type Components } from '@tecnobit-srl/components-store';
import { type Routable } from '@tecnobit-srl/types';
import { useCoreRoutableTypes } from '@tecnobit-srl/routable-types-core';
import { useReviewsRoutableTypes } from '@tecnobit-srl/reviews-routable-types-core';
import { useCoursesRoutableTypes } from '@tecnobit-srl/courses-routable-types-core';

export const useRoutableTypes = (): Components<Routable> => {
  const core = useCoreRoutableTypes();
  const reviews = useReviewsRoutableTypes();
  const courses = useCoursesRoutableTypes();
  return useMemo(
    () => ({
      ...core,
      ...reviews,
      ...courses,
    }),
    [core, reviews, courses]
  );
};
