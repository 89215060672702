import { useMemo } from 'react';
import { type Components } from '@tecnobit-srl/components-store';
import { type Route } from '@tecnobit-srl/types';
import { useCoreRouteTypes } from '@tecnobit-srl/route-types-core';
import { useReviewsRouteTypes } from '@tecnobit-srl/reviews-route-types-core';
import { useCoursesRouteTypes } from '@tecnobit-srl/courses-route-types-core';

export const useRouteTypes = (): Components<{
  route: Route;
}> => {
  const core = useCoreRouteTypes();
  const reviews = useReviewsRouteTypes();
  const courses = useCoursesRouteTypes();
  return useMemo(
    () => ({
      ...core,
      ...reviews,
      ...courses,
    }),
    [core, reviews, courses]
  );
};
