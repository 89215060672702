import {
  type CSSProperties,
  type ReactNode,
  startTransition,
  StrictMode,
  Suspense,
  useEffect,
  useRef,
} from 'react';
import { Style, StylesProvider } from '@tecnobit-srl/styles-manager';
import { Head, HeadProvider } from '@tecnobit-srl/head-manager';
import { LoggerProvider } from '@tecnobit-srl/dev-logger';
import { I18nProvider } from '@tecnobit-srl/i18n';
import { RouterProvider, usePage, useSsrReload } from '@tecnobit-srl/router';
import { type TFunction } from 'i18next';
import { type PageProps } from '@tecnobit-srl/types';
import { DebugBar } from '@tecnobit-srl/dev-debug-bar';
import { RouteTypesProvider, Route } from '@tecnobit-srl/route-types-store';
import { RoutableTypesProvider } from '@tecnobit-srl/routable-types-store';
import { ContentTypesProvider } from '@tecnobit-srl/content-types-store';
import { useThemeStyle } from '@tecnobit-srl/hook-use-theme-style';
import { LightGallery } from '@tecnobit-srl/ui-lightgallery';
import { Spinner } from '@tecnobit-srl/ui-spinner';
import { AuthenticationProvider } from '@tecnobit-srl/authentication';
import { CustomActionsProvider } from '@tecnobit-srl/custom-actions';
import { OverlayProvider } from '@tecnobit-srl/ui-overlay';
import { Menu, MenuManagerContextProvider } from '@tecnobit-srl/menu';
import { zIndexes } from '@tecnobit-srl/ui-zindexes';
import { Breadcrumbs } from '@tecnobit-srl/breadcrumbs';
import { FloatingButtons } from '@tecnobit-srl/floating-buttons';
import { Popups } from '@tecnobit-srl/popups';
import { Footer } from '@tecnobit-srl/footer';
import { ProfilingProvider } from '@tecnobit-srl/profiling';
import { TooltipProvider } from '@tecnobit-srl/ui-tooltip';
import { useRouteTypes } from '../hooks/use-route-types';
import { useRoutableTypes } from '../hooks/use-routable-types';
import { useContentTypes } from '../hooks/use-content-types';
import '../panda.css';
import '../entry.css';
import { css } from '../../styled-system/css';
import { useCustomActions } from '../hooks/use-custom-actions';
import { Seo } from './seo';

const overlayStyle: CSSProperties = {
  zIndex: zIndexes.loadingOverlay,
};

function AppContent(): ReactNode {
  const page = usePage();

  const style = useThemeStyle();

  const ref = useRef<HTMLLinkElement>(null);
  const ssrRefresh = useSsrReload();

  useEffect(() => {
    if (window.fromSsr) {
      startTransition(() => {
        //        void ssrRefresh();
      });
    }
  }, [ssrRefresh]);

  return (
    <div
      className={css({
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: page.route.layoutOptions.centerVertical
          ? 'center'
          : 'start',
      })}
    >
      <Head headKey="theme">
        <style dangerouslySetInnerHTML={{ __html: style }} />
      </Head>
      <Seo />
      {page.settings.googleFont ? (
        <Head headKey="google-font">
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            ref={ref}
            rel="preload"
            href={page.settings.googleFont}
            as="style"
            onLoad={() => {
              const link = ref.current;
              if (link) {
                link.rel = 'stylesheet';
              }
            }}
          />
        </Head>
      ) : null}
      <Popups popups={page.popups} />
      <Menu />
      <Breadcrumbs
        show={page.route.showBreadcrumb}
        breadcrumbs={page.route.breadcrumbs}
      />
      <Suspense>
        <Route {...page.route} />
      </Suspense>
      {!page.route.layoutOptions.hideFooter ? <Footer /> : null}
      <FloatingButtons
        showWhatsapp={page.route.showWhatsapp}
        whatsappNumber={page.settings.whatsappNumber}
        popups={page.popups}
      />
      <DebugBar props={page} />
    </div>
  );
}

export function InnerApp(): ReactNode {
  const customActions = useCustomActions();
  const routeTypes = useRouteTypes();
  const routableTypes = useRoutableTypes();
  const contentTypes = useContentTypes();
  return (
    <CustomActionsProvider customActions={customActions}>
      <RouteTypesProvider components={routeTypes}>
        <RoutableTypesProvider components={routableTypes}>
          <ContentTypesProvider components={contentTypes}>
            <LightGallery id="images">
              <AppContent />
            </LightGallery>
          </ContentTypesProvider>
        </RoutableTypesProvider>
      </RouteTypesProvider>
    </CustomActionsProvider>
  );
}

export function App({
  initialPage,
  i18nInstance,
}: {
  i18nInstance?: TFunction;
  initialPage?: PageProps;
}): ReactNode {
  return (
    <StrictMode>
      <LoggerProvider>
        <HeadProvider>
          <StylesProvider>
            <Style style="entry.client" />
            <RouterProvider
              currentPage={initialPage ?? window.inertiaPage}
              loadingElement={
                <div
                  className={css({
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    w: '100%',
                    h: '100%',
                    background: 'white/80',
                    backdropFilter: 'auto',
                    backdropBlur: 'sm',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  })}
                  style={overlayStyle}
                >
                  <Spinner />
                </div>
              }
            >
              <I18nProvider
                i18nInstance={i18nInstance}
                loadingElement={
                  <div
                    className={css({
                      w: 'full',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    })}
                  >
                    {/* <Spinner /> */}
                  </div>
                }
              >
                <AuthenticationProvider>
                  <MenuManagerContextProvider>
                    <OverlayProvider>
                      <ProfilingProvider>
                        <TooltipProvider>
                          <InnerApp />
                        </TooltipProvider>
                      </ProfilingProvider>
                    </OverlayProvider>
                  </MenuManagerContextProvider>
                </AuthenticationProvider>
              </I18nProvider>
            </RouterProvider>
          </StylesProvider>
        </HeadProvider>
      </LoggerProvider>
    </StrictMode>
  );
}
