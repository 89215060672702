import { useMemo } from 'react';
import { type Components } from '@tecnobit-srl/components-store';
import { type Content } from '@tecnobit-srl/types';
import { useCoreContentTypes } from '@tecnobit-srl/content-types-core';
import { useReviewsContentTypes } from '@tecnobit-srl/reviews-content-types-core';
import { useCoursesContentTypes } from '@tecnobit-srl/courses-content-types-core';

export const useContentTypes = (): Components<Content> => {
  const core = useCoreContentTypes();
  const reviews = useReviewsContentTypes();
  const courses = useCoursesContentTypes();
  return useMemo(
    () => ({
      ...core,
      ...reviews,
      ...courses,
    }),
    [core, reviews, courses]
  );
};
